<template>
    <section id="login_form" data-cy="login_form">
        <form class="login-form" @submit="login">
            <h2 class="welcome">
                {{ $t("login.welcome") }}
            </h2>
            <span v-if="informations === ''" class="description">
                {{ $t("login.description") }}
            </span>
            <p v-else class="description">
                {{ informations }}
            </p>
            <div class="select-wrapper" v-if="isFrLanguage">
                <div
                    :class="{ select: showGestionPart }"
                    @click="selectGestion"
                >
                    {{ $t("MCI.select.gestion") }}
                </div>
                <div
                    :class="{ select: showResidentPart }"
                    @click="selectResident"
                >
                    {{ $t("MCI.select.resident") }}
                </div>
            </div>
            <div class="gestion-container" v-if="showGestionPart">
                <basic-input
                    class="username-field"
                    :disabled="disableLoginButton"
                    v-model="username"
                    name="username"
                    :rules="isNotEmpty"
                    :placeholder="$t('login.id')"
                    :floating="true"
                />
                <password-input
                    class="password-field"
                    :floating="true"
                    :disabled="disableLoginButton"
                    v-model="password"
                    name="password"
                    :rules="isNotEmpty"
                    :placeholder="$t('login.password')"
                />
                <p class="forgot-password" @click="goTo('ForgottenPassword')">
                    {{ $t("login.forgottenPassword") }}
                </p>
                <div>
                    <basic-button
                        class="login-button"
                        type="submit"
                        :disabled="disableLoginButton"
                    >
                        <span v-if="!disableLoginButton">
                            {{ $t("login.connexion") }}
                        </span>
                        <loader
                            v-else
                            class="login-loader"
                            :size="'small'"
                            :color="'white'"
                        >
                        </loader>
                    </basic-button>
                </div>
                <router-link to="register" class="register-link">
                    {{ $t("login.noAccount") }}
                </router-link>
            </div>
            <div class="resident-container" v-if="showResidentPart">
                <p>{{ $t("MCI.access") }}</p>
                <div class="mci-button">
                    <Basic-Button @click="goTo('MCI')">
                        {{ $t("MCI.title") }}
                    </Basic-Button>
                </div>
            </div>
        </form>
    </section>
</template>

<script>
import Loader from "@/components/basic/Loader.vue"
import httpIntratone from "@/services/httpIntratone.js"
import BasicInput from "@/components/basic/BasicInput.vue"
import BasicButton from "@/components/basic/BasicButton.vue"
import PasswordInput from "@/components/basic/PasswordInput.vue"
import {
    getHomeInformations,
    getHomeMessage,
} from "@/services/intratone/home.js"
import { getUserRight } from "@/services/intratone/right.js"
import { useToast } from "vue-toastification"
import { ErrorMessage, useForm } from "vee-validate"
import navigation from "@/mixins/navigation"
import { saveVersionError } from "@/services/intratone/version"
import { mfa } from "@/services/intratone/auth"

const toast = useToast()

export default {
    name: "Login",
    components: {
        BasicInput,
        BasicButton,
        PasswordInput,
        Loader,
    },
    mixins: [navigation],
    inject: ["emitter"],
    setup() {
        const { validate, errors } = useForm()

        return {
            validate,
            errors,
        }
    },
    computed: {
        // isUuid() {
        //     return this.$store.getters["auth/getUuid"]
        // },
        isMFACode() {
            return this.$store.getters["auth/getCode"]
        },
        isFrLanguage() {
            return this.$store.getters["account/getLanguage"] === "fr"
        },
        getEnvironnement() {
            return this.$store.getters["auth/getParam"]
        },
    },
    mounted() {
        // Listen emitter event for modal
        this.emitter.on("close-modal", this.handleEvent)
    },
    data() {
        return {
            username: "",
            password: "",
            disableLoginButton: false,
            informations: "",
            attempt: 0,
            errorMessage: null,
            needToCloseModale: null,
            uuid: null,
            showGestionPart: true,
            showResidentPart: false,
        }
    },
    created() {
        this.autoConnexion()
        this.updateFront()
        this.getMessage()
    },
    methods: {
        isInCodeTable(email, table) {
            const isInCodeTable = table.find((item) => item.login === email)

            if (isInCodeTable) {
                return isInCodeTable
            } else {
                return false
            }
        },
        // If modal is closed
        handleEvent(payload) {
            this.$store.dispatch("doubleAuth/setErrorMessage", "")
            this.attempt = 0
            this.disableLoginButton = false
            clearTimeout(this.needToCloseModale)
        },
        goTo(page) {
            switch (page) {
                case "MCI":
                    if (this.getEnvironnement === "test") {
                        window
                            .open(
                                "https://test.moncompte.intratone.fr/fr/",
                                "_blank"
                            )
                            .focus()
                        break
                    } else {
                        window
                            .open(
                                "https://moncompte.intratone.fr/fr/",
                                "_blank"
                            )
                            .focus()
                        break
                    }
                default:
                    this.$router.push({ name: page })
                    break
            }
        },
        authModale(email, username) {
            this.openModal("double-auth", {
                title: "doubleAuth.title",
                email: email,
                valid: async (code) => {
                    await this.loginWithMFA(code, username)
                },
                refuse: () => {
                    this.closeModal("double-auth")
                },
            })
        },
        generateUUID() {
            return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
                /[xy]/g,
                function (c) {
                    var r = (Math.random() * 16) | 0,
                        v = c == "x" ? r : (r & 0x3) | 0x8
                    return v.toString(16)
                }
            )
        },
        autoConnexion() {
            if (window.location.href.search("protocol=true") > 0) {
                httpIntratone.post("http.php").then((response) => {
                    this.connexion(response)
                })
            } else if (
                location.protocol === "http:" &&
                window.location.hostname !== "localhost" &&
                window.location.hostname !== "192.168.137.1"
            ) {
                location.protocol = "https:"
            }
        },
        updateFront() {
            const versionFront =
                this.$store.getters["account/getVersion"].split(".")
            httpIntratone.post("version.php").then((response) => {
                const versionBack = response.data.v.split(".")
                const reloadString = "reload=true"

                versionBack.forEach((value, index) => {
                    if (value > versionFront[index]) {
                        if (window.location.href.search("manuel=true") > 0) {
                            saveVersionError({
                                vClient:
                                    this.$store.getters["account/getVersion"],
                                vCurrent: response.data.v,
                            })
                        } else if (
                            window.location.href.search(reloadString) > 0
                        ) {
                            toast.warning(
                                this.$t("alerts.version.new_version"),
                                {
                                    icon: "fas fa-sync",
                                }
                            )
                        } else {
                            window.history.pushState(
                                {},
                                "Intratone - Site de gestion",
                                `${window.location.href}?${reloadString}`
                            )
                            this.totalClear()
                        }
                    }
                })
            })
        },
        async getMessage() {
            try {
                // Requesting API to get the global message
                const params = {
                    lng: this.$i18n.locale,
                }
                let messageResponse = await getHomeMessage(params)
                if (messageResponse === false) {
                    throw new Error(this.$t("alerts.errors"))
                }

                if (
                    messageResponse.data.text &&
                    messageResponse.data.text !== ""
                ) {
                    this.informations = messageResponse.data.text
                }
            } catch (error) {
                console.log(error)
                toast.error(error.message, {
                    icon: "fas fa-exclamation-circle",
                    timeout: 10000,
                })
            }
        },
        async loginWithMFA(code, username) {
            let params_login = new URLSearchParams()

            params_login.append("user", username)
            params_login.append("code", code)
            params_login.append("device", this.uuid)
            params_login.append("mfa_login", 1)

            await httpIntratone
                .post("connexion.php", params_login)
                .then((response) => {
                    if (response.data.code === "MFA_CODE_INVALID") {
                        this.attempt++
                        if (this.attempt < 3) {
                            this.$store.dispatch(
                                "doubleAuth/setErrorMessage",
                                this.$t(
                                    "global.modal.doubleAuth.error.attempt",
                                    { count: 3 - this.attempt }
                                )
                            )
                        } else if (this.attempt >= 3) {
                            this.$store.dispatch(
                                "doubleAuth/setErrorMessage",
                                this.$t("global.modal.doubleAuth.error.retry")
                            )

                            this.attempt = 0
                            this.disableLoginButton = false

                            this.needToCloseModale = setTimeout(() => {
                                this.closeModal("double-auth")
                                this.$store.dispatch(
                                    "doubleAuth/setErrorMessage",
                                    ""
                                )
                            }, 10000)
                        }
                    } else if (response.data.code === "MFA_CODE_EXPIRED") {
                        this.$store.dispatch(
                            "doubleAuth/setErrorMessage",
                            this.$t("global.modal.doubleAuth.error.expired")
                        )
                    } else {
                        const newUserSaved = {
                            login: username,
                            uuid: this.uuid,
                            code: code,
                        }

                        const isExist = this.isInCodeTable(
                            username,
                            this.isMFACode
                        )

                        if (!isExist) {
                            this.$store.commit("auth/setCode", newUserSaved)
                        } else {
                            const array = this.$store.getters["auth/getCode"]

                            const index = array.filter((item) => {
                                return item.login !== username
                            })

                            index.push(newUserSaved)

                            this.$store.commit("auth/setUpdateCode", index)
                        }

                        this.$store.commit(
                            "auth/setSignature",
                            response.data.mfa_signature
                        )
                        this.connexion(response)
                        this.$store.dispatch("doubleAuth/setErrorMessage", "")
                    }
                })
        },
        async login(e) {
            e.preventDefault()
            try {
                // Disabling login button to prevent button smash
                this.disableLoginButton = true

                const username = this.username
                const isInCodeTable = this.isInCodeTable(
                    username,
                    this.isMFACode
                )

                let params_login = new URLSearchParams()

                // Validating form
                let validationResponse = await this.validate()
                if (!validationResponse.valid) {
                    throw new Error(this.$t("alerts.connection.invalid"))
                }

                // Check if user is already in code table

                if (!isInCodeTable) {
                    this.uuid = this.generateUUID()
                } else {
                    this.uuid = isInCodeTable.uuid
                    params_login.append("code", isInCodeTable.code)
                }

                params_login.append("identifiant", this.username)
                params_login.append("mdp", this.password)
                params_login.append("device", this.uuid)

                // Requesting v1 site for user authentification
                await httpIntratone
                    .post("connexion.php", params_login)
                    .then((response) => {
                        if (response.data.mfa && !response.data.jwt) {
                            this.authModale(response.data.email, username)
                        } else {
                            this.connexion(response)
                        }
                    })
            } catch (error) {
                this.disableLoginButton = false
                console.log(error)
                toast.error(error.message, {
                    icon: "fas fa-exclamation-circle",
                    timeout: 10000,
                })
            }
        },
        async connexion(authResponse) {
            try {
                if (
                    authResponse.status !== 200 ||
                    authResponse.data ===
                        "Impossible de se connecter ('.sql.intratone.info.') ! (Too many connections)"
                ) {
                    throw new Error("Cannot access intratone.info")
                }

                if (authResponse.data.error === 1) {
                    if (authResponse.data.message === "") {
                        throw new Error(this.$t("alerts.connection.failed"))
                    } else {
                        throw new Error(authResponse.data.message)
                    }
                }

                if (window.location.hostname !== "localhost") {
                    if (
                        parseInt(authResponse.data.https) === 0 &&
                        location.protocol === "https:"
                    ) {
                        let httpString = ""
                        if (window.location.href.search("reload=true") > 0) {
                            httpString += "&"
                        } else {
                            httpString += "?"
                        }
                        httpString += "protocol=true"
                        window.history.pushState(
                            {},
                            "Intratone - Site de gestion",
                            `${window.location.href}${httpString}`
                        )
                        location.protocol = "http:"
                    }
                }
                // Storing Prog Version
                localStorage.setItem(
                    "PROG_VERSION",
                    authResponse.data.PROG_VERSION
                )

                // Storing user state
                if (authResponse.data.session) {
                    this.$store.commit("auth/setJwtValues", {
                        jwt: authResponse.data.session.jwt,
                        expireAt: authResponse.data.session.jwt_expire,
                        jwtRenew: authResponse.data.session.renew,
                        renewExpireAt: authResponse.data.session.renew_expire,
                    })

                    localStorage.setItem(
                        "apiintra_jwt",
                        authResponse.data.session.jwt
                    )
                    localStorage.setItem(
                        "apiintra_jwt_expire",
                        authResponse.data.session.jwt_expire
                    )
                    localStorage.setItem(
                        "apiintra_renew_expire",
                        authResponse.data.session.renew_expire
                    )
                    localStorage.setItem('csrf', authResponse.data.CSRF)
                    this.$store.commit("auth/setLogged", true)
                }

                this.$store.commit("account/setId", authResponse.id)
                if (authResponse.data.define) {
                    this.$store.commit(
                        "account/setDefine",
                        authResponse.data.define
                    )
                    this.$store.commit(
                        "account/setCountry",
                        authResponse.data.define.country
                    )
                }

                authResponse.data.ROBOT
                    ? this.$store.commit("account/setIpass", true)
                    : null

                this.$store.commit("auth/setLogged", true)

                // TEMP
                if (authResponse.data.SID) {
                    this.$store.commit(
                        "auth/setIntratoneDev2SID",
                        authResponse.data.SID
                    )
                }

                if (authResponse.data.CSRF) {
                    localStorage.setItem('csrf', authResponse.data.CSRF)
                }

                // Requesting API for user rights
                let userRightsResponse = await getUserRight()
                if (
                    userRightsResponse === false ||
                    userRightsResponse.data.error
                ) {
                    throw new Error(this.$t("alerts.connection.failed"))
                } else {
                    const rightList = []
                    userRightsResponse.data.rights &&
                        userRightsResponse.data.rights.list.forEach((e) => {
                            rightList.push(e.define)
                        })
                    this.$store.commit("auth/setRights", rightList)

                    const privilegesList = []
                    userRightsResponse.data.privileges &&
                        userRightsResponse.data.privileges.list.forEach((e) => {
                            privilegesList.push(e.keyname)
                        })
                    this.$store.commit("auth/setPrivileges", privilegesList)

                    this.$store.commit(
                        "account/setLogin",
                        userRightsResponse.data.compte
                    )

                    this.$store.commit(
                        "account/setAdmin",
                        rightList.length === 1 && rightList[0] === "ADMIN"
                    )
                }

                // Requesting API for global account informations
                const params = {
                    user: +true,
                    messages: "unread",
                }
                let homeResponse = await getHomeInformations(params)
                if (homeResponse === false) {
                    throw new Error(this.$t("alerts.connection.failed"))
                }

                // Storing global account informations
                this.$store.commit(
                    "account/setFirstName",
                    homeResponse.data.user.prenom
                )
                this.$store.commit(
                    "account/setLastName",
                    homeResponse.data.user.nom
                )

                // Redirecting
                if (authResponse.data.define["ACCUEIL"] === "0") {
                    this.$store.commit("account/setAccueil", 0)
                    this.initHomeToBreadCrumb("résidences")

                    if (this.checkRight("G_RESIDENCES")) {
                        this.$router.push({
                            name: "Residences",
                        })
                    } else if (
                        this.checkRight("G_TABL_GERE") ||
                        this.checkRight("G_TABL_VISU")
                    ) {
                        this.$router.push({
                            name: "V1BulletinBoard",
                        })
                    } else {
                        this.$router.push({
                            name: "V1",
                            params: {
                                arg1: "data",
                                arg2: "accueil",
                                arg3: "accueil",
                            },
                        })
                    }
                    if (localStorage.getItem("lastDateNotification")) {
                        this.$store.commit(
                            "account/setLastDateNotification",
                            localStorage.getItem("lastDateNotification")
                        )
                    }
                } else {
                    this.$store.commit("account/setAccueil", 1)
                    this.initHomeToBreadCrumb("menu")
                    this.$router.push({
                        name: "V1",
                        params: {
                            arg1: "data",
                            arg2: "accueil",
                            arg3: "accueil",
                        },
                    })
                }

                // Alert : connected
                toast.success(
                    this.$t("alerts.connection.connected", {
                        name: this.$store.getters["account/getFullName"],
                    }),
                    {
                        icon: "fas fa-power-off",
                    }
                )
            } catch (error) {
                this.disableLoginButton = false
                console.log(error)
                toast.error(error.message, {
                    icon: "fas fa-exclamation-circle",
                    timeout: 10000,
                })
            }
        },
        isNotEmpty(value) {
            if (value === "" || value === null || value === undefined) {
                return this.$t("input.empty")
            }
            return true
        },
        selectGestion() {
            this.showGestionPart = true
            this.showResidentPart = false
        },
        selectResident() {
            this.showGestionPart = false
            this.showResidentPart = true
		},

        initHomeToBreadCrumb(direction) {
            this.$store.commit("breadCrumb/setClassicBreadCrumb", [
                {
                    name: this.$t("global.entities.home"),
                    link:
                        direction === "résidences"
                            ? "/residences"
                            : "/v1/data/accueil/accueil",
                },
            ])
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

@media all and (min-width: 1024px) {
    .login-form {
        width: 500px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
    .login-form {
        width: 60vw;
        margin: unset;
        overflow: auto;
    }
}

@media all and (max-width: 768px) {
    .login-form {
        width: 100vw;
        margin: unset;
        overflow: auto;
    }
}

.login-form {
    text-align: center;
    padding: 20px 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: $normal;
    font-weight: 300;
    line-height: 19px;
}

.welcome {
    font-family: $font_avenir_black;
    font-size: $veryBig;
    padding: 0 50px 30px 50px;
    margin: 0;
}

.description {
    font-family: $font_avenir_book;
    font-size: $normal;
    padding: 0 50px 20px 50px;
    margin: 0;
}

.select-wrapper {
    width: 100%;
    display: flex;
    background: rgba($pink-lighter, $alpha: 0.4);
    border-bottom: 3px solid $pink-lighter;
    border-radius: 10px 10px 0 0;
    font-family: $font_avenir_heavy;
    font-size: 16px;

    div {
        padding: 1em 2em;
        transition: all 0.2s ease-in-out;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @media all and (max-width: 1024px) {
            padding: 1em 0.5em;
        }

        &:hover {
            cursor: pointer;
            background-color: $pink-lighter;
            border-radius: 10px 10px 0 0;
            transition: all 0.2s ease-in-out;
        }
    }

    .select {
        background-color: $pink-lighter;
        border-radius: 10px 10px 0 0;
        transition: all 0.2s ease-in-out;

        &:hover {
            cursor: default;
        }
    }
}

.gestion-container {
    width: 100%;
    padding-top: 20px;
}

.resident-container {
    width: 100%;
    padding-top: 20px;
    height: 335px;

    p {
        margin: 0;
    }

    .mci-button {
        padding-top: 20px;
    }
}

.username-field {
    width: 100%;
    margin-bottom: 15px;

    input {
        text-align: center;
        height: 60px;
    }

    .form-floating > label {
        width: 100%;
    }
}

.password-field {
    width: 100%;
    margin-bottom: 15px;

    input {
        text-align: center;
        height: 60px;
    }

    .form-floating > label {
        width: 100%;
    }
}

.forgot-password {
    font-family: $font_avenir_heavy;
    font-size: $small;
    color: $grey;
    text-underline-offset: 4px;
    margin-bottom: 50px;
    text-decoration: underline;
}

.forgot-password:hover {
    color: $blue-light;
    cursor: pointer;
}

.login-button {
    width: 250px;
    margin-bottom: 20px;
    height: 60px;
}

.register-link {
    font-family: $font_avenir_heavy;
    font-size: $small;
    color: $orange-neutral;
    text-underline-offset: 4px;
    text-decoration: underline;
}

.register-link:hover {
    color: $blue-lighter;
}
</style>
